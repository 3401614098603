import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table } from 'react-bootstrap';
import { baseURL } from './APIBaseURL';

function Invoices() {
  const [invoices, setInvoices] = useState([]); // Store invoices fetched from the backend
  const [invoiceType, setInvoiceType] = useState('Client');  // Default to "Client"
  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: '',
    invoiceDate: '',
    description: '',
    totalAmount: 0,
    client: { name: '', phone: '', email: '' },
    vendor: { name: '', items: [{ description: '', quantity: 0, total: 0 }] },
    associate: { name: '', mobile: '', email: '', associateId: '', payoutAmount: 0 }
  });

  // Fetch all invoices from the backend when the component is mounted
  useEffect(() => {
    fetchInvoices();
    fetchInvoiceNumber(); // Fetch a unique invoice number from the backend
  }, []);

  // Fetch the next available invoice number from the backend
  const fetchInvoiceNumber = async () => {
    try {
      const response = await axios.get(baseURL+'api/invoices/next-invoice-number');
      setInvoiceData((prevData) => ({
        ...prevData,
        invoiceNumber: response.data.nextInvoiceNumber
      }));
    } catch (error) {
      console.error('Error fetching the next invoice number:', error);
    }
  };

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(baseURL+'api/invoices/all');
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  // Handle form submission to create a new invoice
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare invoice data based on the invoice type (Client, Vendor, or Associate)
    let preparedInvoiceData = { 
      invoiceNumber: invoiceData.invoiceNumber,
      invoiceDate: invoiceData.invoiceDate,
      description: invoiceData.description,
      totalAmount: invoiceData.totalAmount,
      type: invoiceType // Set the type for validation in the backend
    };

    if (invoiceType === 'Client') {
      preparedInvoiceData.client = { 
        name: invoiceData.client.name, 
        phone: invoiceData.client.phone, 
        email: invoiceData.client.email 
      };
    } else if (invoiceType === 'Vendor') {
      preparedInvoiceData.vendor = {
        name: invoiceData.vendor.name,
        items: invoiceData.vendor.items.map(item => ({
          description: item.description,
          quantity: item.quantity,
          total: item.total
        }))
      };
    } else if (invoiceType === 'Associate') {
      preparedInvoiceData.associate = {
        name: invoiceData.associate.name,
        mobile: invoiceData.associate.mobile,
        email: invoiceData.associate.email,
        associateId: invoiceData.associate.associateId,
        payoutAmount: invoiceData.associate.payoutAmount
      };
    }

    // Log the prepared invoice data to ensure it's correctly populated
    console.log('Submitting invoice data:', preparedInvoiceData);

    try {
      const response = await axios.post(baseURL+'api/invoices/create', preparedInvoiceData);

      if (response.status === 201) {
        alert('Invoice Created Successfully');
        fetchInvoices(); // Fetch the updated list of invoices
        resetForm();
        fetchInvoiceNumber(); // Fetch a new invoice number after submitting
      } else {
        console.error('Failed to create invoice:', response.data);
        alert('Error: Failed to create invoice. Check the form data.');
      }
    } catch (error) {
      console.error('Error creating invoice:', error.response?.data || error.message);
      alert('Error creating invoice. Check the console for details.');
    }
  };

  // Handle changes in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  // Handle changes in client, vendor, or associate specific fields
  const handleSpecificChange = (type, field, value) => {
    const newData = { ...invoiceData[type], [field]: value };
    setInvoiceData({ ...invoiceData, [type]: newData });
  };

  // Add a new item to the vendor items array
  const handleAddItem = () => {
    setInvoiceData({
      ...invoiceData,
      vendor: { ...invoiceData.vendor, items: [...invoiceData.vendor.items, { description: '', quantity: 0, total: 0 }] }
    });
  };

  // Handle changes in individual vendor item fields
  const handleItemChange = (index, field, value) => {
    const newItems = [...invoiceData.vendor.items];
    newItems[index][field] = value;
    setInvoiceData({ ...invoiceData, vendor: { ...invoiceData.vendor, items: newItems } });
  };

  // Reset the form after submitting
  const resetForm = () => {
    setInvoiceData({
      invoiceNumber: '',
      invoiceDate: '',
      description: '',
      totalAmount: 0,
      client: { name: '', phone: '', email: '' },
      vendor: { name: '', items: [{ description: '', quantity: 0, total: 0 }] },
      associate: { name: '', mobile: '', email: '', associateId: '', payoutAmount: 0 }
    });
  };

  return (
    <Container>
      <h2>Create Invoice</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Invoice Number</Form.Label>
          <Form.Control type="text" name="invoiceNumber" value={invoiceData.invoiceNumber} readOnly />
        </Form.Group>

        <Form.Group>
          <Form.Label>Select Invoice Type</Form.Label>
          <Form.Control as="select" value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)}>
            <option value="Client">Client</option>
            <option value="Vendor">Vendor</option>
            <option value="Associate">Associate</option>
          </Form.Control>
        </Form.Group>

        {/* Common Fields */}
        <Form.Group>
          <Form.Label>Invoice Date</Form.Label>
          <Form.Control type="date" name="invoiceDate" value={invoiceData.invoiceDate} onChange={handleChange} required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control type="text" name="description" value={invoiceData.description} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Amount</Form.Label>
          <Form.Control type="number" name="totalAmount" value={invoiceData.totalAmount} onChange={handleChange} required />
        </Form.Group>

        {/* Client Form */}
        {invoiceType === 'Client' && (
          <>
            <Form.Group>
              <Form.Label>Client Name</Form.Label>
              <Form.Control type="text" value={invoiceData.client.name} onChange={(e) => handleSpecificChange('client', 'name', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Client Phone</Form.Label>
              <Form.Control type="text" value={invoiceData.client.phone} onChange={(e) => handleSpecificChange('client', 'phone', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Client Email</Form.Label>
              <Form.Control type="email" value={invoiceData.client.email} onChange={(e) => handleSpecificChange('client', 'email', e.target.value)} required />
            </Form.Group>
          </>
        )}

        {/* Vendor Form */}
        {invoiceType === 'Vendor' && (
          <>
            <Form.Group>
              <Form.Label>Vendor Name</Form.Label>
              <Form.Control type="text" value={invoiceData.vendor.name} onChange={(e) => handleSpecificChange('vendor', 'name', e.target.value)} required />
            </Form.Group>
            <h4>Items</h4>
            {invoiceData.vendor.items.map((item, index) => (
              <div key={index}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" value={item.description} onChange={(e) => handleItemChange(index, 'description', e.target.value)} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type="number" value={item.quantity} onChange={(e) => handleItemChange(index, 'quantity', e.target.value)} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Total</Form.Label>
                  <Form.Control type="number" value={item.total} onChange={(e) => handleItemChange(index, 'total', e.target.value)} />
                </Form.Group>
              </div>
            ))}
            <Button onClick={handleAddItem}>Add Item</Button>
          </>
        )}

        {/* Associate Form */}
        {invoiceType === 'Associate' && (
          <>
            <Form.Group>
              <Form.Label>Associate Name</Form.Label>
              <Form.Control type="text" value={invoiceData.associate.name} onChange={(e) => handleSpecificChange('associate', 'name', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Associate Mobile</Form.Label>
              <Form.Control type="text" value={invoiceData.associate.mobile} onChange={(e) => handleSpecificChange('associate', 'mobile', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Associate Email</Form.Label>
              <Form.Control type="email" value={invoiceData.associate.email} onChange={(e) => handleSpecificChange('associate', 'email', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Associate ID</Form.Label>
              <Form.Control type="text" value={invoiceData.associate.associateId} onChange={(e) => handleSpecificChange('associate', 'associateId', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Payout Amount</Form.Label>
              <Form.Control type="number" value={invoiceData.associate.payoutAmount} onChange={(e) => handleSpecificChange('associate', 'payoutAmount', e.target.value)} required />
            </Form.Group>
          </>
        )}

        <Button type="submit">Create Invoice</Button>
      </Form>

      {/* Display the submitted invoices */}
      <h3>Submitted Invoices</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Invoice Date</th>
            <th>Type</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice._id}>
              <td>{invoice.invoiceNumber}</td>
              <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>
              <td>{invoice.type}</td>
              <td>{invoice.client?.name || invoice.vendor?.name || invoice.associate?.name}</td>
              <td>{invoice.client?.phone || invoice.associate?.mobile || 'N/A'}</td>
              <td>{invoice.client?.email || invoice.associate?.email || 'N/A'}</td>
              <td>{invoice.totalAmount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Invoices;
