import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table } from 'react-bootstrap';  // Import Bootstrap components
import { baseURL } from './APIBaseURL';

function FarmerReport() {
  const [farmers, setFarmers] = useState([]);
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [contact, setContact] = useState('');
  const [landSize, setLandSize] = useState('');
  const [purchaseRate, setPurchaseRate] = useState('');  // State for Purchase Rate
  const [description, setDescription] = useState('');  // State for Description

  useEffect(() => {
    // Fetch all farmers when the component loads
    axios.get(baseURL+'api/farmers')
      .then(response => setFarmers(response.data))
      .catch(error => console.error('Error fetching farmers:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFarmer = { name, location, contact, landSize, purchaseRate, description };

    try {
      // Send farmer data to the backend
      const response = await axios.post(baseURL+'api/farmers/add', newFarmer, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update the state with the new farmer
      setFarmers([...farmers, response.data]);

      // Reset form fields
      setName('');
      setLocation('');
      setContact('');
      setLandSize('');
      setPurchaseRate('');
      setDescription('');
    } catch (error) {
      console.error('Error adding farmer:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Farmer Report</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter farmer name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLocation" className="mb-3">
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formContact" className="mb-3">
          <Form.Label>Contact</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLandSize" className="mb-3">
          <Form.Label>Land Size (Sqft)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter land size in Sqft"
            value={landSize}
            onChange={(e) => setLandSize(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPurchaseRate" className="mb-3">
          <Form.Label>Purchase Rate</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter purchase rate"
            value={purchaseRate}
            onChange={(e) => setPurchaseRate(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Farmer
        </Button>
      </Form>

      <h3 className="mt-4">Submitted Farmers</h3>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Contact</th>
            <th>Land Size (Acres)</th>
            <th>Purchase Rate</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {farmers.map((farmer) => (
            <tr key={farmer._id}>
              <td>{farmer.name}</td>
              <td>{farmer.location}</td>
              <td>{farmer.contact}</td>
              <td>{farmer.landSize}</td>
              <td>{farmer.purchaseRate}</td>
              <td>{farmer.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default FarmerReport;
