import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table } from 'react-bootstrap';  // Bootstrap components
import { baseURL } from './APIBaseURL';

function EmployeeDetails() {
  const [employees, setEmployees] = useState([]);
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dateOfJoining, setDateOfJoining] = useState('');
  const [employeeId, setEmployeeId] = useState('');  // New employeeId state
  const [salary, setSalary] = useState('');          // New salary state
  const [salaryStatus, setSalaryStatus] = useState('Not Paid');  // Default value

  // Fetch all employees when the component loads
  useEffect(() => {
    axios.get(baseURL+'api/employees')
      .then(response => setEmployees(response.data))
      .catch(error => console.error('Error fetching employees:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newEmployee = {
      name,
      position,
      email,
      phone,
      dateOfJoining,
      employeeId,  // Include employeeId
      salary,      // Include salary
      salaryStatus
    };

    try {
      // Send employee data to the backend
      const response = await axios.post(baseURL+'api/employees/add', newEmployee, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update the state with the new employee
      setEmployees([...employees, response.data]);

      // Reset form fields
      setName('');
      setPosition('');
      setEmail('');
      setPhone('');
      setDateOfJoining('');
      setEmployeeId('');  // Reset employeeId field
      setSalary('');      // Reset salary field
      setSalaryStatus('Not Paid');
    } catch (error) {
      console.error('Error adding employee:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Employee Details</h2>
      
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPosition" className="mb-3">
          <Form.Label>Position</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPhone" className="mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Enter phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDateOfJoining" className="mb-3">
          <Form.Label>Date of Joining</Form.Label>
          <Form.Control
            type="date"
            value={dateOfJoining}
            onChange={(e) => setDateOfJoining(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmployeeId" className="mb-3">
          <Form.Label>Employee ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter employee ID"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formSalary" className="mb-3">
          <Form.Label>Salary</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter salary"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formSalaryStatus" className="mb-3">
          <Form.Label>Salary Status</Form.Label>
          <Form.Control
            as="select"
            value={salaryStatus}
            onChange={(e) => setSalaryStatus(e.target.value)}
            required
          >
            <option value="Paid">Paid</option>
            <option value="Not Paid">Not Paid</option>
            <option value="Half Paid">Half Paid</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Employee
        </Button>
      </Form>

      <h3 className="mt-4">Employee List</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Employee ID</th> {/* New column for employeeId */}
            <th>Name</th>
            <th>Position</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Date of Joining</th>
            <th>Salary</th> {/* New column for salary */}
            <th>Salary Status</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => (
            <tr key={employee._id}>
              <td>{employee.employeeId}</td>  {/* Display employeeId */}
              <td>{employee.name}</td>
              <td>{employee.position}</td>
              <td>{employee.email}</td>
              <td>{employee.phone}</td>
              <td>{new Date(employee.dateOfJoining).toLocaleDateString()}</td>
              <td>{employee.salary}</td>  {/* Display salary */}
              <td>{employee.salaryStatus}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default EmployeeDetails;
