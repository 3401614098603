import React from 'react';
import logo from '../assets/images/logo.png';  // Adjust the path if necessary

function Dashboard() {
  return (
    <div className="container mt-4">
      <div className="text-center">
        <img src={logo} alt="Company Logo" style={{ width: '250px', marginBottom: '20px' }} />
        <h1>Welcome to the Dashboard</h1>
        <p>This is the main dashboard where you can access different features of the CRM.</p>
      </div>
    </div>
  );
}

export default Dashboard;
