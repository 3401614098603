import React, { useState, useEffect } from 'react';  // Import React and hooks
import axios from 'axios';  // Import Axios for HTTP requests
import { Container, Form, Button, Table } from 'react-bootstrap';  // Import Bootstrap components
import { baseURL } from './APIBaseURL';

function AssociateDetails() {
  const [associates, setAssociates] = useState([]);
  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const [associateId, setAssociateId] = useState('');

  // Fetch all associates when the component loads
  useEffect(() => {
    axios.get(baseURL+'api/associates')
      .then(response => setAssociates(response.data))
      .catch(error => console.error('Error fetching associates:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newAssociate = {
      name,
      mobileNo,
      email,
      associateId
    };

    try {
      // Send associate data to the backend
      const response = await axios.post(baseURL+'api/associates/add', newAssociate, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update the state with the new associate
      setAssociates([...associates, response.data]);

      // Reset form fields
      setName('');
      setMobileNo('');
      setEmail('');
      setAssociateId('');
    } catch (error) {
      console.error('Error adding associate:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Associate Details</h2>
      
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter associate name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobileNo" className="mb-3">
          <Form.Label>Mobile No</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Enter mobile number"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAssociateId" className="mb-3">
          <Form.Label>Associate ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter associate ID"
            value={associateId}
            onChange={(e) => setAssociateId(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Associate
        </Button>
      </Form>

      <h3 className="mt-4">Associate List</h3>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile No</th>
            <th>Email</th>
            <th>Associate ID</th>
          </tr>
        </thead>
        <tbody>
          {associates.map((associate) => (
            <tr key={associate._id}>
              <td>{associate.name}</td>
              <td>{associate.mobileNo}</td>
              <td>{associate.email}</td>
              <td>{associate.associateId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default AssociateDetails;
