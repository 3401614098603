import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

function Navigation({ setIsAuthenticated }) {
  const history = useHistory(); // useHistory for redirection

  const handleLogout = () => {
    // Clear any authentication tokens or user info from storage
    localStorage.removeItem('authToken'); // Example if you're using localStorage
    setIsAuthenticated(false); // Update the authenticated state

    // Redirect to login page
    history.push('/login');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand as={Link} to="/dashboard">Abhiditya Groups CRM</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
          <Nav.Link as={Link} to="/invoices">Invoices</Nav.Link>
          <Nav.Link as={Link} to="/reminders">Reminders</Nav.Link>
          <Nav.Link as={Link} to="/documents">Documents</Nav.Link>
          <Nav.Link as={Link} to="/landsales-report">Land Sales Report</Nav.Link>
          <Nav.Link as={Link} to="/employee-details">Employee Details</Nav.Link>
          <Nav.Link as={Link} to="/farmer-report">Farmer Report</Nav.Link>
          <Nav.Link as={Link} to="/associate-details">Associate Details</Nav.Link>
          <Nav.Link as={Link} to="/bills">Bills</Nav.Link> {/* New link for Bills */}
          <Nav.Link as={Link} to="/investors">Investors</Nav.Link> {/* New link for Investors */}
        </Nav>

        {/* Log Out button */}
        <Button variant="outline-light" onClick={handleLogout}>
          Log Out
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
