import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Table, Alert } from 'react-bootstrap';
import { baseURL } from './APIBaseURL';

function Investors() {
  const [investors, setInvestors] = useState([]);
  const [notification, setNotification] = useState(null); // For success/error notification
  const [error, setError] = useState(null); // For displaying errors
  const [calculatedAmount, setCalculatedAmount] = useState(0); // For calculated payment
  const [investorData, setInvestorData] = useState({
    name: '',
    fatherOrSpouseName: '',
    dob: '',
    adhar: '',
    pan: '',
    email: '',
    contactNo: '',
    investmentAmount: 0,
    percentageToPay: 0,
    investmentMethod: 'Monthly',
    bankDetails: {
      accountNo: '',
      ifsc: '',
      bankName: '',
      bankBranch: '',
      accountHolder: '',
      accountType: 'Saving',
    },
    nominee: {
      name: '',
      dob: '',
      relation: '',
    },
    associate: {
      name: '',
      idNo: '', // Associate ID field
    },
  });

  useEffect(() => {
    fetchInvestors();
  }, []);

  const fetchInvestors = async () => {
    try {
      const response = await axios.get(baseURL+'api/investors');
      console.log('API Response:', response.data); // Log the API response
      setInvestors(response.data);
    } catch (error) {
      setError('Error fetching investors');
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle nested fields for bankDetails, nominee, and associate
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setInvestorData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value,
        },
      }));
    } else {
      setInvestorData({ ...investorData, [name]: value });
    }
  };

  // Calculate investment based on method and percentage
  const calculateInvestmentAmount = useCallback(() => {
    const { investmentAmount, percentageToPay, investmentMethod } = investorData;
    const percentage = parseFloat(percentageToPay) / 100;
    const baseAmount = parseFloat(investmentAmount);

    if (investmentMethod === 'Monthly') {
      const monthlyAmount = (baseAmount * percentage) / 12;
      setCalculatedAmount(monthlyAmount.toFixed(2));
    } else if (investmentMethod === 'Annually') {
      const yearlyAmount = baseAmount * percentage;
      setCalculatedAmount(yearlyAmount.toFixed(2));
    }
  }, [investorData]);

  useEffect(() => {
    calculateInvestmentAmount(); // Recalculate when investment data changes
  }, [investorData.investmentAmount, investorData.percentageToPay, investorData.investmentMethod, calculateInvestmentAmount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting Investor Data:', investorData); // Log the payload
      await axios.post(baseURL+'api/investors/create', investorData);
      setNotification('Investor data saved successfully.');
      fetchInvestors(); // Refresh the list
      resetForm();
    } catch (error) {
      console.error('Error submitting investor data:', error);
      setError('Error submitting data. Please try again.');
    }
  };  

  const resetForm = () => {
    setInvestorData({
      name: '',
      fatherOrSpouseName: '',
      dob: '',
      adhar: '',
      pan: '',
      email: '',
      contactNo: '',
      investmentAmount: 0,
      percentageToPay: 0,
      investmentMethod: 'Monthly',
      bankDetails: {
        accountNo: '',
        ifsc: '',
        bankName: '',
        bankBranch: '',
        accountHolder: '',
        accountType: 'Saving',
      },
      nominee: {
        name: '',
        dob: '',
        relation: '',
      },
      associate: {
        name: '',
        idNo: '',
      },
    });
    setCalculatedAmount(0);
  };

  return (
    <Container>
      <h2 className="my-4">Investors Data</h2>

      {/* Display notification */}
      {notification && <Alert variant="success">{notification}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      <Form onSubmit={handleSubmit}>
        {/* Investor Details */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="name">
              <Form.Label>Investor Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={investorData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="fatherOrSpouseName">
              <Form.Label>Father/Spouse Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Father/Spouse Name"
                name="fatherOrSpouseName"
                value={investorData.fatherOrSpouseName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={investorData.dob}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId="adhar">
              <Form.Label>Aadhar Number</Form.Label>
              <Form.Control
                type="text"
                name="adhar"
                placeholder="Enter Aadhar Number"
                value={investorData.adhar}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId="pan">
              <Form.Label>PAN Number</Form.Label>
              <Form.Control
                type="text"
                name="pan"
                placeholder="Enter PAN"
                value={investorData.pan}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={investorData.email}
                onChange={handleChange}
                placeholder="Enter Email"
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="contactNo">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                name="contactNo"
                value={investorData.contactNo}
                onChange={handleChange}
                placeholder="Enter Contact Number"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Investment Info */}
        <h4 className="my-4">Investment Details</h4>
        <Row>
          <Col md={4}>
            <Form.Group controlId="investmentAmount">
              <Form.Label>Investment Amount</Form.Label>
              <Form.Control
                type="number"
                name="investmentAmount"
                value={investorData.investmentAmount}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId="percentageToPay">
              <Form.Label>Percentage to Pay (%)</Form.Label>
              <Form.Control
                type="number"
                name="percentageToPay"
                placeholder="Enter Percentage to Pay"
                value={investorData.percentageToPay}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId="investmentMethod">
              <Form.Label>Investment Method</Form.Label>
              <Form.Control
                as="select"
                name="investmentMethod"
                value={investorData.investmentMethod}
                onChange={handleChange}
              >
                <option value="Monthly">Monthly</option>
                <option value="Annually">Annually</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h5>Calculated Payment: {calculatedAmount}</h5>
          </Col>
        </Row>

        {/* Bank Details */}
        <h4 className="my-4">Bank Details</h4>
        <Row>
          <Col md={6}>
            <Form.Group controlId="accountNo">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                name="bankDetails.accountNo"
                placeholder="Enter Account Number"
                value={investorData.bankDetails.accountNo}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="ifsc">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                name="bankDetails.ifsc"
                placeholder="Enter IFSC Code"
                value={investorData.bankDetails.ifsc}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="bankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="bankDetails.bankName"
                placeholder="Enter Bank Name"
                value={investorData.bankDetails.bankName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="bankBranch">
              <Form.Label>Bank Branch</Form.Label>
              <Form.Control
                type="text"
                name="bankDetails.bankBranch"
                placeholder="Enter Bank Branch"
                value={investorData.bankDetails.bankBranch}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="accountHolder">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                type="text"
                name="bankDetails.accountHolder"
                placeholder="Enter Account Holder Name"
                value={investorData.bankDetails.accountHolder}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="accountType">
              <Form.Label>Account Type</Form.Label>
              <Form.Control
                as="select"
                name="bankDetails.accountType"
                value={investorData.bankDetails.accountType}
                onChange={handleChange}
              >
                <option value="Saving">Saving</option>
                <option value="Current">Current</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Nominee Details */}
        <h4 className="my-4">Nominee Details</h4>
        <Row>
          <Col md={6}>
            <Form.Group controlId="nomineeName">
              <Form.Label>Nominee Name</Form.Label>
              <Form.Control
                type="text"
                name="nominee.name"
                placeholder="Enter Nominee Name"
                value={investorData.nominee.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="nomineeDOB">
              <Form.Label>Nominee Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="nominee.dob"
                value={investorData.nominee.dob}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="nomineeRelation">
              <Form.Label>Relation with Nominee</Form.Label>
              <Form.Control
                type="text"
                name="nominee.relation"
                placeholder="Enter Relation"
                value={investorData.nominee.relation}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Associate Details */}
        <h4 className="my-4">Associate Details</h4>
        <Row>
          <Col md={6}>
            <Form.Group controlId="associateName">
              <Form.Label>Associate Name</Form.Label>
              <Form.Control
                type="text"
                name="associate.name"
                placeholder="Enter Associate Name"
                value={investorData.associate.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="associateIdNo">
              <Form.Label>Associate ID No.</Form.Label>
              <Form.Control
                type="text"
                name="associate.idNo"
                placeholder="Enter Associate ID"
                value={investorData.associate.idNo}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="my-4">
          Submit
        </Button>
      </Form>

      {/* Investor List Table */}
      <h4 className="my-4">Investor List</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Father/Spouse Name</th>
            <th>Contact No</th>
            <th>Email</th>
            <th>Investment Amount</th>
            <th>Associate Name</th>
            <th>Associate ID No.</th>
          </tr>
        </thead>
        <tbody>
          {investors.map((investor) => (
            <tr key={investor._id}>
              <td>{investor.name}</td>
              <td>{investor.fatherOrSpouseName}</td>
              <td>{investor.contactNo}</td>
              <td>{investor.email}</td>
              <td>{investor.investmentAmount || investor.investAmount}</td> 
              <td>{investor.associate?.name || 'N/A'}</td>
              <td>{investor.associate?.idNo || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Investors;
  