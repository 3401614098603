import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'; // Use Switch from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap for styling
import Navbar from './components/Navbar'; // Navbar Component
import Dashboard from './components/Dashboard'; // Dashboard Component
import Invoices from './components/Invoices'; // Invoices Component
import Reminders from './components/Reminders'; // Reminders Component
import Documents from './components/Documents'; // Documents Component
import LandSalesReport from './components/LandSalesReport'; // LandSalesReport Component
import EmployeeDetails from './components/EmployeeDetails'; // EmployeeDetails Component
import FarmerReport from './components/FarmerReport'; // FarmerReport Component
import AssociateDetails from './components/AssociateDetails'; // AssociateDetails Component
import Login from './components/Login'; // Login Component
import CreateBillForm from './components/CreateBillForm'; // CreateBillForm Component (New Bill creation feature)
import Investors from './components/Investors'; // Investors Component (New Investors feature)
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track user login status

  return (
    <Router>
      <div>
        {/* Show Navbar only if user is authenticated */}
        {isAuthenticated && <Navbar setIsAuthenticated={setIsAuthenticated} />} {/* Pass setIsAuthenticated to Navbar */}

        <Switch>
          {/* Login Route */}
          <Route 
            path="/login" 
            render={() => isAuthenticated ? <Redirect to="/dashboard" /> : <Login setIsAuthenticated={setIsAuthenticated} />} 
          />

          {/* Protected Routes */}
          <Route 
            path="/dashboard" 
            render={() => isAuthenticated ? <Dashboard /> : <Redirect to="/login" />}
          />
          <Route 
            path="/invoices" 
            render={() => isAuthenticated ? <Invoices /> : <Redirect to="/login" />}
          />
          <Route 
            path="/reminders" 
            render={() => isAuthenticated ? <Reminders /> : <Redirect to="/login" />}
          />
          <Route 
            path="/documents" 
            render={() => isAuthenticated ? <Documents /> : <Redirect to="/login" />}
          />
          <Route 
            path="/landsales-report" 
            render={() => isAuthenticated ? <LandSalesReport /> : <Redirect to="/login" />}
          />
          <Route 
            path="/employee-details" 
            render={() => isAuthenticated ? <EmployeeDetails /> : <Redirect to="/login" />}
          />
          <Route 
            path="/farmer-report" 
            render={() => isAuthenticated ? <FarmerReport /> : <Redirect to="/login" />}
          />
          <Route 
            path="/associate-details" 
            render={() => isAuthenticated ? <AssociateDetails /> : <Redirect to="/login" />}
          />

          {/* New Route for Bill Creation */}
          <Route 
            path="/bills" 
            render={() => isAuthenticated ? <CreateBillForm /> : <Redirect to="/login" />}
          />

          {/* New Route for Investors */}
          <Route 
            path="/investors" 
            render={() => isAuthenticated ? <Investors /> : <Redirect to="/login" />}
          />

          {/* Default Route */}
          <Route 
            exact path="/" 
            render={() => <Redirect to="/login" />} // Default route redirects to login
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
