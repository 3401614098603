import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table } from 'react-bootstrap'; // Bootstrap components
import { baseURL, imgURL } from './APIBaseURL';

function Documents() {
  const [documents, setDocuments] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [documentFile, setDocumentFile] = useState(null);

  // Fetch all documents when the component loads
  useEffect(() => {
    axios.get(baseURL+'api/documents')
      .then(response => setDocuments(response.data))
      .catch(error => console.error('Error fetching documents:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('documentFile', documentFile);

    try {
      // Send document data to the backend
      const response = await axios.post(baseURL+'api/documents/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Update the state with the new document
      setDocuments([...documents, response.data]);

      // Reset form fields
      setTitle('');
      setDescription('');
      setDocumentFile(null);
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Documents</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle" className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter document title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter document description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Upload Document</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setDocumentFile(e.target.files[0])}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Upload Document
        </Button>
      </Form>

      <h3 className="mt-4">Uploaded Documents</h3>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc) => (
            <tr key={doc._id}>
              <td>{doc.title}</td>
              <td>{doc.description}</td>
              <td>
                <a
                target='_blank'
                  href={imgURL+`${doc.documentPath}`} // Ensure the correct download link
                  download  // This triggers the download action
                >
                  Download Document
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Documents;
