import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Row, Col, Table, Image } from 'react-bootstrap';
import { baseURL } from './APIBaseURL';

function CreateBillForm() {
  const [billData, setBillData] = useState({
    invoiceNumber: '',
    billDate: '',
    details: {
      name: '',
      address: '',
      phone: '',
      email: ''
    },
    items: [{ description: '', quantity: 0, total: 0 }],
    totalAmount: 0,
    paymentDetails: {
      bankName: '',
      accountNumber: '',
      ifscCode: ''
    },
  });

  const [generatedInvoiceNumber, setGeneratedInvoiceNumber] = useState('');

  // Fetch the next available invoice number from the backend
  useEffect(() => {
    const fetchNextInvoiceNumber = async () => {
      try {
        const response = await axios.get(baseURL+'api/bills/next-invoice-number');
        setBillData((prevData) => ({
          ...prevData,
          invoiceNumber: response.data.nextInvoiceNumber
        }));
        setGeneratedInvoiceNumber(response.data.nextInvoiceNumber);
      } catch (error) {
        console.error('Error fetching the next invoice number:', error);
      }
    };
    fetchNextInvoiceNumber();
  }, []);

  const handleItemChange = (index, field, value) => {
    const newItems = [...billData.items];
    newItems[index][field] = value;
    setBillData({ ...billData, items: newItems });
  };

  const handleAddItem = () => {
    setBillData({
      ...billData,
      items: [...billData.items, { description: '', quantity: 0, total: 0 }]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting bill data:', billData);  // Log the bill data before sending
      const response = await axios.post(baseURL+'api/bills/create', billData);
      alert('Bill Created Successfully');
      
      // Display the generated invoice number
      setGeneratedInvoiceNumber(response.data.invoiceNumber);

      // After creation, reset form
      setBillData({
        invoiceNumber: '',
        billDate: '',
        details: {
          name: '',
          address: '',
          phone: '',
          email: ''
        },
        items: [{ description: '', quantity: 0, total: 0 }],
        totalAmount: 0,
        paymentDetails: {
          bankName: '',
          accountNumber: '',
          ifscCode: ''
        }
      });
    } catch (error) {
      console.error('Error creating bill', error);
    }
  };

  const handlePrint = () => {
    window.print();  // Trigger print functionality
  };

  return (
    <Container className="mt-4">
      {/* Add logo at the top of the bill */}
      <Row className="mb-4">
        <Col className="text-center">
          <Image src="/images/logo.png" alt="Company Logo" fluid style={{ maxWidth: '200px' }} />
        </Col>
      </Row>

      <h2>Bill</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formInvoiceNumber">
              <Form.Label>Invoice Number</Form.Label>
              <Form.Control
                type="text"
                value={billData.invoiceNumber || generatedInvoiceNumber}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formBillDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                value={billData.billDate}
                onChange={(e) => setBillData({ ...billData, billDate: e.target.value })}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Details */}
        <h3>Details</h3>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={billData.details.name}
                onChange={(e) => setBillData({ ...billData, details: { ...billData.details, name: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={billData.details.address}
                onChange={(e) => setBillData({ ...billData, details: { ...billData.details, address: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Phone and Email Fields */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={billData.details.phone}
                onChange={(e) => setBillData({ ...billData, details: { ...billData.details, phone: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={billData.details.email}
                onChange={(e) => setBillData({ ...billData, details: { ...billData.details, email: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Items */}
        <h3>Items</h3>
        <Table striped bordered hover className="mb-3">
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {billData.items.map((item, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                    type="text"
                    value={item.description}
                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.total}
                    onChange={(e) => handleItemChange(index, 'total', e.target.value)}
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button variant="secondary" onClick={handleAddItem}>
          Add Item
        </Button>

        {/* Total Amount */}
        <h3>Total Amount</h3>
        <Form.Group controlId="formTotalAmount">
          <Form.Label>Total Amount</Form.Label>
          <Form.Control
            type="number"
            value={billData.totalAmount}
            onChange={(e) => setBillData({ ...billData, totalAmount: e.target.value })}
            required
          />
        </Form.Group>

        {/* Payment Instructions */}
        <h3>Payment Instructions</h3>
        <Row>
          <Col md={4}>
            <Form.Group controlId="formBankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                value={billData.paymentDetails.bankName}
                onChange={(e) => setBillData({ ...billData, paymentDetails: { ...billData.paymentDetails, bankName: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formAccountNumber">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                value={billData.paymentDetails.accountNumber}
                onChange={(e) => setBillData({ ...billData, paymentDetails: { ...billData.paymentDetails, accountNumber: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formIfscCode">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                value={billData.paymentDetails.ifscCode}
                onChange={(e) => setBillData({ ...billData, paymentDetails: { ...billData.paymentDetails, ifscCode: e.target.value } })}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Company Details */}
        <h3>Company Details</h3>
        <Row>
          <Col>
            <p><strong>Company Name:</strong> Abhiditya Group</p>
            <p><strong>Company Address:</strong> Plot no 11 Gangapuri Colony, Near Laxmi Takies, Mahmoorganj, Varanasi, Uttar Pradesh 221010</p>
            <p><strong>Phone Number:</strong> +918810802978</p>
            <p><strong>Email Address:</strong> Contactus@abhidityagroup.com</p>
          </Col>
        </Row>

        {/* Authorized Signature */}
        <h3>Authorized Signature</h3>
        <Row>
          <Col className="text-right">
            <p>__________________________</p>
            <p><strong>Authorized Signature</strong></p>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Create Bill
        </Button>
        {' '}
        <Button variant="info" onClick={handlePrint}>
          Print Bill
        </Button>

        {/* Display the generated invoice number */}
        {generatedInvoiceNumber && (
          <p className="mt-4">
            <strong>Generated Invoice Number:</strong> {generatedInvoiceNumber}
          </p>
        )}
      </Form>

      {/* Print-specific CSS for A4 size */}
      <style>
        {`
          @media print {
            body {
              width: 210mm;
              height: 297mm;
              margin: 10mm;
            }
            .container {
              padding: 0;
              font-size: 12px;
            }
            h2, h3 {
              font-size: 14pt;
            }
            .form-label, .form-control {
              font-size: 12pt;
            }
            .table {
              font-size: 12pt;
            }
            .table td, .table th {
              font-size: 10pt;
            }
            .text-right {
              text-align: right;
            }
          }
        `}
      </style>
    </Container>
  );
}

export default CreateBillForm;
