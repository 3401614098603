import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table } from 'react-bootstrap'; // Bootstrap components
import { baseURL } from './APIBaseURL';

function Reminders() {
  const [reminders, setReminders] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [description, setDescription] = useState('');
  const [emiAmount, setEmiAmount] = useState('');
  const [bookingAmount, setBookingAmount] = useState('');
  const [bookingDate, setBookingDate] = useState('');

  // Fetch reminders on component mount
  useEffect(() => {
    axios.get(baseURL+'api/reminders')
      .then(response => setReminders(response.data))
      .catch(error => console.error('Error fetching reminders:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newReminder = {
      customerName,
      mobileNo,
      description,
      emiAmount,
      bookingAmount,
      bookingDate
    };

    try {
      // Send reminder data to the backend
      const response = await axios.post(baseURL+'api/reminders/add', newReminder, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Add the new reminder to the state
      setReminders([...reminders, response.data]);

      // Reset form fields
      setCustomerName('');
      setMobileNo('');
      setDescription('');
      setEmiAmount('');
      setBookingAmount('');
      setBookingDate('');
    } catch (error) {
      console.error('Error adding reminder:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Reminders</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formCustomerName" className="mb-3">
          <Form.Label>Customer Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter customer name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobileNo" className="mb-3">
          <Form.Label>Mobile No</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Enter mobile number"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmiAmount" className="mb-3">
          <Form.Label>EMI Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter EMI amount"
            value={emiAmount}
            onChange={(e) => setEmiAmount(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formBookingAmount" className="mb-3">
          <Form.Label>Booking Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter booking amount"
            value={bookingAmount}
            onChange={(e) => setBookingAmount(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formBookingDate" className="mb-3">
          <Form.Label>Booking Date</Form.Label>
          <Form.Control
            type="date"
            value={bookingDate}
            onChange={(e) => setBookingDate(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Reminder
        </Button>
      </Form>

      <h3 className="mt-4">Upcoming EMI Dates and Booking Status</h3>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Mobile No</th>
            <th>Description</th>
            <th>EMI Amount</th>
            <th>Booking Amount</th>
            <th>Booking Date</th>
            <th>Next EMI Date</th>
            <th>Days Left for Booking</th>
          </tr>
        </thead>
        <tbody>
          {reminders.map((reminder) => (
            <tr key={reminder._id}>
              <td>{reminder.customerName}</td>
              <td>{reminder.mobileNo}</td>
              <td>{reminder.description}</td>
              <td>{reminder.emiAmount}</td>
              <td>{reminder.bookingAmount}</td>
              <td>{new Date(reminder.bookingDate).toLocaleDateString()}</td>
              <td>{reminder.nextEMIDate}</td>
              <td>{reminder.daysLeftForBooking}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Reminders;
