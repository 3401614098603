import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table } from 'react-bootstrap'; // Import Bootstrap components
import { baseURL } from './APIBaseURL';

function LandSalesReport() {
  const [landSales, setLandSales] = useState([]);
  const [buyerName, setBuyerName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [location, setLocation] = useState('');
  const [landSize, setLandSize] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  // Fetch all land sales when the component loads
  useEffect(() => {
    axios.get(baseURL+'api/land-sales')  // Correct API route
      .then(response => setLandSales(response.data))
      .catch(error => console.error('Error fetching land sales:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newLandSale = {
      buyerName,
      mobileNo,
      location,
      landSize,
      amount,
      description
    };

    try {
      // Send land sale data to the backend
      const response = await axios.post(baseURL+'api/land-sales/add', newLandSale, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update the state with the new land sale
      setLandSales([...landSales, response.data]);

      // Reset form fields
      setBuyerName('');
      setMobileNo('');
      setLocation('');
      setLandSize('');
      setAmount('');
      setDescription('');
    } catch (error) {
      console.error('Error adding land sale:', error);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Land Sales Report</h2>
      
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBuyerName" className="mb-3">
          <Form.Label>Name of Buyer</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter buyer name"
            value={buyerName}
            onChange={(e) => setBuyerName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMobileNo" className="mb-3">
          <Form.Label>Mobile No</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Enter mobile number"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLocation" className="mb-3">
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLandSize" className="mb-3">
          <Form.Label>Land Size (Sqft)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter land size in sqft"
            value={landSize}
            onChange={(e) => setLandSize(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAmount" className="mb-3">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Land Sale
        </Button>
      </Form>

      <h3 className="mt-4">Submitted Land Sales</h3>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Buyer Name</th>
            <th>Mobile No</th>
            <th>Location</th>
            <th>Land Size (Sqft)</th>
            <th>Amount</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {landSales.map((sale) => (
            <tr key={sale._id}>  {/* Ensure unique key */}
              <td>{sale.buyerName}</td>
              <td>{sale.mobileNo}</td>
              <td>{sale.location}</td>
              <td>{sale.landSize}</td>
              <td>{sale.amount}</td>
              <td>{sale.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default LandSalesReport;
