import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';  // Use `useHistory` for navigation
import { Container, Form, Button } from 'react-bootstrap';
import logo from '../assets/images/logo.png';  // Import the logo image

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === 'rishusingh8115@gmail.com' && password === 'Admin@123') {
      setIsAuthenticated(true);  // Mark user as authenticated
      history.push('/dashboard');  // Navigate to the dashboard
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="border p-4 bg-light" style={{ width: '400px' }}>
        {/* Logo */}
        <div className="text-center mb-4">
          <img src={logo} alt="Company Logo" style={{ width: '250px' }} />  {/* Adjust the size here */}
        </div>
        
        <h2 className="text-center mb-4">CRM Login</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100">
            Login
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default Login;
